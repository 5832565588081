import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { routes } from "./utils/routes";
import { authRoutes } from "./utils/auth-path";
import Login from "./pages/auth/login";
import FeatureModule from "./pages/feature-module/feature-module";
import ForgotPassword from "./pages/auth/forgot-password";
import OtpSuccess from "./pages/auth/otp-success";
import { favicon } from "./utils/imagepath";
import Otp from "./pages/auth/otp";
import ResetPassword from "./pages/auth/reset-password";
import { useDispatch } from "react-redux";
import { setDomain } from "../src/core/redux/loginSlice";
import { end_points } from "./services/end_point/end_points";
import { ApiServiceContext } from "./services/api/api.service";
import { setEditData } from "./core/redux/commonSlice";
import RedirectAuthenticated from "./services/route_guards/redirectAuthenticated";
import ProtectedRoute from "./services/route_guards/protectedRoute";

const unAuth = [
  {
    id: 1,
    path: "/",
    element: <Navigate to={routes.login.path} />,
  },
  {
    id: 2,
    path: routes.login.path,
    element: <Login />,
  },
  {
    id: 3,
    path: routes.forgotPassword.path,
    element: <ForgotPassword />,
  },
  {
    id: 4,
    path: routes.otpSuccess.path,
    element: <OtpSuccess />,
  },
  {
    id: 5,
    path: routes.otp.path,
    element: <Otp />,
  },
  {
    id: 6,
    path: routes.resetPassword.path,
    element: <ResetPassword />,
  },
  {
    id: 7,
    path: routes.adminLogin.path,
    element: <Login />,
  },
  {
    id: 8,
    path: routes.adminForgotPassword.path,
    element: <ForgotPassword />,
  },
  {
    id: 9,
    path: routes.adminOtpSuccess.path,
    element: <OtpSuccess />,
  },
  {
    id: 10,
    path: routes.adminOtp.path,
    element: <Otp />,
  },
  {
    id: 11,
    path: routes.adminResetPassword.path,
    element: <ResetPassword />,
  },
];

const auth = authRoutes;

const AppRouter: React.FC = () => {
  const dispatch = useDispatch();
  const { getData } = useContext(ApiServiceContext);
  const path = window.location.hostname;
  const findPath = window.location.pathname;
  const isAdmin = findPath.split("/")[1];
  const domain = path.split(".")[0].replace("https://", "");

  if (path === 'localhost') {
    var domainName = 'test';
  } else {
    var domainName = domain;
    // var domainName = 'vm';
  }

  const checkDomain = async () => {
    try {
      let urls = end_points.verify_domain.url;
      urls += `?domainName=${domainName}`;

      const response = await getData(urls);
      if (response?.status == 200) {
        dispatch(setDomain(domainName));
        // dispatch(setEditData(undefined));
      }
    } catch (error: any) {}
  };

  useEffect(() => {

    const faviconLink: any = document.getElementById(
      "favicon"
    ) as HTMLLinkElement;
    if (faviconLink) {
      faviconLink.href = favicon;
    } else {
      console.error("Favicon link element not found");
    }
    if (isAdmin != "super-admin") {
      checkDomain();
    }
    dispatch(setEditData(undefined));

  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FeatureModule />}>
          {unAuth.map((item) => (
            <Route key={item.id} path={item.path} element={<RedirectAuthenticated>{item.element}</RedirectAuthenticated>} />
          ))}
          {auth.map((item) => (
            <Route key={item.id} path={item.path} element={<ProtectedRoute>{item.element}</ProtectedRoute>} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
