import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import PrimeDataTable from "../../../../components/data-table";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import CompanyDetails from "./company-details";
import { setSelectedData } from "../../../../core/redux/commonSlice";
import { formatDate } from "../../../../utils/patterns/regex.pattern";

const CompanyList = ({
  setStatusCount = (data: any) => {},
  setCountData = (data: any) => {},
}) => {
  const { getData, putData } = useContext(ApiServiceContext);
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(listData.map((item) => item.id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    tutorialId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(tutorialId);
    } else {
      newSelectedRows.delete(tutorialId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const company_id = row;
    try {
      const payload = {
        id: company_id,
        isActive: isChecked,
      };
      let url = end_points.update_companystatus.url;

      const response = await putData(url, payload);
      if (response.status === 200) {
        setStatusCount((prev: any) => !prev);
        setListData((prevData: any) =>
          prevData.map((row: any) =>
            row.id === company_id ? { ...row, status: isChecked } : row
          )
        );
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data.id)}
              onChange={(e) => handleRowCheckboxChange(e, data.id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "ID",
      field: "id",
      key: "id",
      sortField: "id",
    },
    {
      header: "Company Name",
      field: "company_name",
      key: "company_name",
      sortField: "company_name",
      body: (data: any) => (
        <div>
          {data.company_name}
          {data.companyPlans.length > 0 && (
            <>
              {data.companyPlans[0].flagColor === 2 ? (
                <div className="tooltips primary-tooltip">
                  <i className="ti ti-info-circle " />
                  <span className="tooltiptexts">
                    Plan Will Expire on{" "}
                    {formatDate(data.companyPlans[0].expiry_Date)}
                  </span>
                </div>
              ) : data.companyPlans[0].flagColor === 1 ? (
                <div className="tooltips danger-tooltip">
                  <i className="ti ti-info-circle " />
                  <span className="tooltiptexts">
                    Plan Expired {formatDate(data.companyPlans[0].expiry_Date)}
                  </span>
                </div>
              ) : null}
            </>
          )}
        </div>
      ),
    },
    {
      header: "Contact Person",
      field: "contact_person_name",
      key: "contact_person_name",
      sortField: "contact_person_name",
      body: (data: any) => (
        <h2 className="table-avatar">
          <Link to="#" className="avatar avatar-sm me-2">
            <img
              className="avatar-img rounded-circle"
              src={data.company_image_Path}
              alt="Profiles"
            />
          </Link>
          <Link to="#">{data.contact_person_name}</Link>
        </h2>
      ),
    },
    {
      header: "Fleet Manger",
      field: "fleet_manager",
      key: "fleet_manager",
      sortField: "fleet_manager",
      body: (data: any) => (
        <span className="text-muted">{data.fleet_manager}</span>
      ),
    },
    {
      header: "Service Manger",
      field: "service_manager",
      key: "service_manager",
      sortField: "service_manager",
      body: (data: any) => (
        <span className="text-muted">{data.service_manager}</span>
      ),
    },
    {
      header: "Drivers",
      field: "drivers",
      key: "drivers",
      sortField: "drivers",
      body: (data: any) => <span className="text-muted">{data.drivers}</span>,
    },
    {
      header: "No of Trucks",
      field: "nooftruck",
      key: "nooftruck",
      sortField: "nooftruck",
      body: (data: any) => (
        <span className="badge badge-primary">
          <i className="ti ti-bus me-2" />
          {data.nooftruck}
        </span>
      ),
    },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortField: "status",
      body: (data: any) => (
        <div className="toggles">
          <label className="switch">
            <input
              type="checkbox"
              id="togBtn"
              checked={data.status === 1}
              onChange={(event) => handleToggle(event, data.id)}
            />
            <div className="slider round">
              <span className="on">
                <i className="ti ti-check" />
              </span>
              <span className="off">
                <i className="ti ti-x" />
              </span>
            </div>
          </label>
        </div>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (data: any) => {
        return (
          <>
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  to="#"
                  className="dropdown-item" 
                  onClick={() => {
                    handleOffcanvas();
                    dispatch(setSelectedData(data));
                  }}
                >
                  <i className="feather icon-eye" /> View
                </Link>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const [count, setCount] = useState<number>(0);

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.get_companies.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.companies);
        setCount(response?.data?.totalCount);
        setTotalRecords(response?.data?.totalCount);
        setCount(1);
        setCountData(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const dispatch = useDispatch();

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  return (
    <>
      <div className="card table-card">
        <div className="card-body">
          <div className="table-responsive prime-table">
            <PrimeDataTable
              setSearchQuery={setSearchQuery}
              column={columns}
              data={listData}
              rows={rows}
              setRows={setRows}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
      <CompanyDetails />
    </>
  );
};

export default CompanyList;
