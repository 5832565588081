import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { routes } from '../../utils/shared.module';

interface ProtectedRouteProps {
  children: ReactNode;
}

const RedirectAuthenticated = ({ children }: ProtectedRouteProps) => {
  const token = localStorage.getItem('token');
  const role = useSelector((state: any) => state.login.role);
  const navigate = useNavigate();

  if (token) {
    if (role === 1) {
      navigate(routes.adminDashboard.path);
    } else if (role === 3) {
      navigate(routes.fleetManagerDashboard.path);
    } else if (role === 4) {
      navigate(routes.serviceManagerDashboard.path);
    } else {
      navigate(routes.dashboard.path);
    }
    return null;
  }

  return <>{children}</>;
};

export default RedirectAuthenticated;