import React from "react";
import { Link } from "react-router-dom";
import { emptyLogo, free } from "../../../../utils/imagepath";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { formatDate } from "../../../../utils/patterns/regex.pattern";
import { getExpiryMessage } from "../../../../utils/constant";

const CompanyDetails = () => {
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const dispatch = useDispatch();

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };
  const data = useSelector((state: any) => state.common.selectedData);
  return (
    <>
      <div
        className={`truck-list-sidebar company-details ${
          offcanvas ? "truck-list-sidebar-open" : ""
        }`}
      >
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Company Details</h4>
            <Link to="#" className="clear-truck-list" onClick={handleOffcanvas}>
              <i className="fas fa-xmark" />
            </Link>
          </div>
          <div className="card-body">
            <h6 className="mb-1">ID - {data.id}</h6>
            <p className="text-muted">Company Details</p>
            <div className="container p-0">
              {/* Driver Basic Profile */}
              <div className="popup-driver-profile">
                <div className="cc-driver-content">
                  <div className="card success-border mb-3">
                    <div className="card-body">
                      <div className="row driver-widget">
                        <div className="col-sm-3 col-6">
                          <div className="driver-imgs w-100">
                            <img
                              src={data.company_image_Path}
                              className="img-fluid w-100"
                              alt="Driver Profile"
                            />
                          </div>
                        </div>
                        <div className="col-sm-9 driver-list-info">
                          <div className="driver-details">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="driver-base-profile mb-3">
                                  <div className="d-flex align-items-center justify-content-between flex-wrap company-name">
                                    <h5>
                                      {data.company_name}
                                      <span
                                        className={`badge ${
                                          data.status === 1
                                            ? "bg-light-success"
                                            : "bg-light-danger-sp"
                                        }`}
                                      >
                                        {data.status === 1
                                          ? "Active"
                                          : "In active"}
                                      </span>
                                    </h5>

                                    {data.pricingPlans &&
                                    data.pricingPlans.length > 0 ? (
                                      <>
                                        <span>
                                          {data.pricingPlans[0].planName}
                                        </span>
                                      </>
                                    ) : (
                                      "No plan available"
                                    )}
                                  </div>
                                  <div className="driver-infos mt-2">
                                    <ul>
                                      <li>
                                        <h6>
                                          Cameron Jacob <span>(Owner)</span>
                                        </h6>
                                      </li>
                                      <li>
                                        <h5>
                                          <span>
                                            <i className="ti ti-mail-check me-1" />{" "}
                                            <span>{data.company_email}</span>
                                          </span>
                                        </h5>
                                        <h5>
                                          <span>
                                            <i className="ti ti-phone-check me-1" />{" "}
                                            <span>{data.company_phone}</span>
                                          </span>
                                        </h5>
                                      </li>
                                      <li>
                                        <h5>
                                          <span>
                                            <i className="ti ti-map-pin-check me-1" />{" "}
                                            <span>{data.address}</span>
                                          </span>
                                        </h5>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="details-heading">
                    <h5>Other Details</h5>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="row truck-widget">
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Contact Person Name</p>
                            <h6>{data.contact_person_name}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>No of Vehicles</p>
                            <span className="badge badge-primary">
                              <i className="ti ti-bus me-2" />
                              {data.nooftruck}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Contact Number</p>
                            <h6>{data.contact_person_phone}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Contact Email</p>
                            <h6>{data.contact_person_email}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Domain</p>
                            <h6>{data.domain_name}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>No of Fleet Managers</p>
                            <h6>{data.fleet_manager}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>No of Service Managers</p>
                            <h6>{data.service_manager}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>No of Drivers</p>
                            <h6>{data.drivers}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Plan Expiry</p>
                            <h6>
                              {data.companyPlans &&
                              data.companyPlans.length > 0 ? (
                                <>
                                  {formatDate(data.companyPlans[0].expiry_Date)}{" "}
                                  <span className="text-danger">
                                  {getExpiryMessage(data.companyPlans[0].expiry_Date)}
                                  </span>
                                </>
                              ) : (
                                "No expiry date available"
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex align-items-center justify-content-between mb-3 mt-4">
                    <h6>Status</h6>
                    <div className="toggles">
                      <label className="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          checked={data.status === 1}
                        />
                        <div className="slider round">
                          <span className="on">
                            <i className="ti ti-check" />
                          </span>
                          <span className="off">
                            <i className="ti ti-x" />
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* Driver Basic Profile */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
