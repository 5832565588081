import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../utils/shared.module";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { toast } from "react-toastify";

const AddService = (props: any) => {
  const validationSchema = Yup.object().shape({
    vehical_number:Yup.string(),
    service_task: Yup.string()
      .required("Service task title is required")
      .trim(),
    components: Yup.array().of(
      Yup.object().shape({
        component: Yup.string().required("Component title is required"),
        issue: Yup.string().required("Repair status is required"),
      })
    ),
    description: Yup.string().required("Description is required").trim(),
    serviceManager: Yup.object({
      name: Yup.string().trim().required("ServiceManager is required"),
    })
      .nullable()
      .required("ServiceManager is required"),
  });

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<any>(validationSchema),
    defaultValues: {
      vehical_number: props?.vehicleData?.vehicle?.vehical_number,
      service_task: "",
      components: [],
      description: "",
      serviceManager: { name: "", code: "" },
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "components",
  });
  const { postData, getData } = useContext(ApiServiceContext);
  const user_id = useSelector((state: any) => state.login.userDetails.id);
  const editData = useSelector((state: any) => state.common.editData);
  const serviceComponentData = useSelector((state: any) => state.common.serviceVehicleData);

  const componentdummyData= [
    { component: "componentOne", code: "Low" },
    { component: "componentTwo", code: "Medium" },
    { component: "componentThree", code: "High" },
  ];
  const [componentData, setComponentData] = useState<any>([]);

  const issue = [
    { name: "Low", code: "Low" },
    { name: "Medium", code: "Medium" },
    { name: "High", code: "High" },
  ];

  const onSubmit = async (data: any) => {
    try {
      const payload = {
        repair_name: data.service_task,
        complaint: data.description,
        user_id: data.serviceManager.code,
        inspection_id: serviceComponentData?.insp_Id,
        type_of_repair: 2,
        components: data.components,
        created_by: user_id,
      };

      const urls = end_points.add_service.url;
      const response = await postData(urls, payload);
      if (response.status === 200) {
        props.closeServiceModal()
        toast.success(response?.data?.response?.responseMessage)
        props.getList()
      }
    } catch (e) {
    }
  };

  const handleAddComponent = () => {
    append({ component: "", issue: "" });
  };

  const handleRemoveComponent = (index: number) => {
    remove(index);
  };

  const getRepairComponent = async () => {
    try {
      let url = end_points.get_RepairComponents.url;
      url += `?inspection_Refid=${serviceComponentData?.inspection_refid}`;
      const response = await getData(url);
      if (response.status === 200) {
        setComponentData(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRepairComponent();
  }, []);

  const initialized = useRef(false); 

  useEffect(() => {
    if (!initialized.current && fields.length === 0 && componentData.length > 0) {
      componentData?.forEach((item:any) => {
        append({ component: item.name, issue: "Low" });
      });
      initialized.current = true; 
    }
  }, [append, fields.length, componentData]);

  return (
    <>
      <div
        className="modal modal-dialog-customized fade show"
        id="add-service"
        tabIndex={-1}
        aria-labelledby="newvehicle"
        aria-hidden="true"
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Service Summary</h5>
              <Link
                to="#"
                className="clear-truck-lists"
                data-bs-dismiss="modal"
                onClick={props.closeServiceModal}
              >
                <i className="fas fa-xmark" />
              </Link>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} id="serviceForm">
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                  <div className="mb-3">
                      <label className="form-label">
                        Vehicle Number <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="vehical_number"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className="form-control"
                            placeholder="Enter vehicle name"
                            disabled
                          />
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        Service Task Title <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="service_task"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className="form-control"
                            placeholder="Enter Full Name"
                            onBlur={() => trigger("service_task")}
                            onKeyDown={(e) => {
                              if (
                                !/[a-zA-Z\s]/.test(e.key) &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        )}
                      />
                      {errors.service_task && (
                        <small className="text-danger">
                          {errors.service_task.message}
                        </small>
                      )}
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label>
                          Trip Description <span className="manitary">*</span>
                        </label>
                        <Controller
                          name="description"
                          control={control}
                          render={({ field }) => (
                            <textarea
                              className={`form-control  ${
                                errors?.description ? "error-input" : ""
                              }`}
                              {...field}
                              autoComplete="false"
                              maxLength={255}
                              onBlur={() => trigger("description")}
                              placeholder="Enter type description"
                            />
                          )}
                        />
                        <small className="text-danger">
                          {errors?.description?.message}
                        </small>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        Choose Service Manager{" "}
                        <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="serviceManager"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Dropdown
                              filter
                              {...field}
                              value={field.value}
                              onBlur={() => trigger("serviceManager")}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger("serviceManager");
                              }}
                              options={props.managerListData} 
                              optionLabel="name"
                              placeholder="Select service manager"
                              className={`w-100 ${
                                errors.serviceManager ? "is-invalid" : ""
                              }`}
                            />
                          </>
                        )}
                      />
                      {errors.serviceManager && (
                        <small className="text-danger">
                          {errors.serviceManager?.name?.message}
                        </small>
                      )}
                    </div>
                    {/* <div className="col-12 mb-2 text-end">
                      <Link
                        to="#"
                        className="link-add"
                        onClick={handleAddComponent}
                      >
                        <i className="ti ti-plus" /> Add
                      </Link>
                    </div> */}
                    {fields.map((item, index) => (
                      <div className="component-div" key={item.id}>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-2">
                              <label className="form-label">
                                Components <span className="manitary">*</span>
                              </label>
                              <Controller
                                name={`components.${index}.component`}
                                control={control}
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    type="text"
                                    placeholder="Title Name"
                                    className="form-control"
                                    onBlur={() => trigger("components")}
                                    disabled
                                  />
                                )}
                              />
                              {errors.components?.[index]?.component && (
                                <small className="text-danger">
                                  {errors.components[index].component.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-2">
                              <label className="form-label">
                                Repair Status<span className="manitary">*</span>
                              </label>
                              <Controller
                                name={`components.${index}.issue`}
                                control={control}
                                render={({ field }) => (
                                  <Dropdown
                                    filter
                                    options={issue}
                                    optionLabel="name"
                                    placeholder="Select Repair Status"
                                    className="w-100"
                                    value={
                                      issue.find(
                                        (option) => option.name === field.value
                                      ) || null
                                    }
                                    onChange={(e) => {
                                      field.onChange(
                                        e.value ? e.value.name : ""
                                      );
                                      trigger(
                                        `components.${index}.issue`
                                      );
                                    }}
                                  />
                                )}
                              />
                              {errors.components?.[index]?.issue && (
                                <small className="text-danger">
                                  {
                                    errors.components[index].issue
                                      .message
                                  }
                                </small>
                              )}
                            </div>
                          </div>
                          {/* {index > 0 && (
                            <div className="col-12 text-end">
                              <Link
                                to="#"
                                className="link-remove"
                                onClick={() => handleRemoveComponent(index)}
                              >
                                <i className="ti ti-remove" /> Remove
                              </Link>
                            </div>
                          )} */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-add">
                  Update
                </button>
                <button
                  onClick={props.closeServiceModal}
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddService;
