import parsePhoneNumberFromString from "libphonenumber-js";
import { formatDistanceStrict, differenceInDays } from 'date-fns';

export const capitalizeFirstLetter = (str: string) => {
    return str
      ? str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase()
      : str;
  };  
  export const validatePhoneNumber = (value: string) => {
    if (!value.startsWith('+')) {
      value = `+${value}`;
    }
    const phoneNumber = parsePhoneNumberFromString(value);
    return phoneNumber ? phoneNumber.isValid() : false;
  };



  export const getExpiryMessage = (expiryDate: string) => {
      const today = new Date();
      const expiry = new Date(expiryDate);
      const daysLeft = differenceInDays(expiry, today);
  
      if (daysLeft <= 0) {
        return <span className="text-danger">(Expired)</span>;
      }
  
      if (daysLeft <= 30) {
        return <span className="text-danger">({daysLeft} Days Left)</span>;
      }
  
      const balanceTime = formatDistanceStrict(today, expiry, {
        unit: 'day',
        addSuffix: true,
      });
  
      if (daysLeft > 365) {
        return (
          <span className="text-danger">
            ({Math.floor(daysLeft / 365)} Years, {Math.floor((daysLeft % 365) / 30)} Months, {(daysLeft % 365) % 30} Days Left)
          </span>
        );
      } else if (daysLeft > 30) {
        return (
          <span className="text-danger">
            ({Math.floor(daysLeft / 30)} Months, {daysLeft % 30} Days Left)
          </span>
        );
      }
  
      return <span className="text-danger">({balanceTime})</span>;
    };