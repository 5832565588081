import { createSlice } from "@reduxjs/toolkit";
import { redirectToLogout } from "./localStorage";

type state = {
  token: any;
  userName: any;
  userDetails: null | any;
  companyDetails: null | any;
  planDetails: null | any;
  domain: any;
  role: number | null;
};

const initialState: state = {
  token: "",
  userName: "",
  userDetails: {},
  domain: "",
  role: null,
  companyDetails: {},
  planDetails: {},
};

const appSlice = createSlice({ 
  name: "login",
  initialState,
  reducers: {
    setLoginToken: (state, { payload }) => {
      state.token = payload;
    },
    setUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
    setCompanyDetails: (state, { payload }) => {
      state.companyDetails = payload;
    },
    setPlanDetails: (state, { payload }) => {
      state.planDetails = payload;
    },
    setDomain: (state, { payload }) => {
      state.domain = payload;
    },
    setUserName: (state, { payload }) => {
      state.userName = payload;
    },
    setRole: (state, { payload }) => {
      state.role = payload;
    },
    // logout: (state: any) => {
    //   state.loading = false;
    //   state.token = '';
    //   redirectToLogout();
    // },
    logout: (state: any, { payload }) => {
      state.loading = false;
      state.token = "";
      localStorage.removeItem('token')
      redirectToLogout(payload);
    },
  },
});

export const {
  setLoginToken,
  setUserName,
  logout,
  setUserDetails,
  setDomain,
  setRole,
  setCompanyDetails,
  setPlanDetails
} = appSlice.actions;

export default appSlice.reducer;