import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import PrimeDataTable from "../../../../components/data-table";
import { useDispatch } from "react-redux";
import { setRepairViewData } from "../../../../core/redux/commonSlice";
import { ConfirmDialog } from "primereact/confirmdialog";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../utils/shared.module";
import { formatDate } from './../../../../utils/patterns/regex.pattern';

const RepairList = () => {
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [listData, setListData] = useState<any>([]);
  const [rows, setRows] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { getData } = useContext(ApiServiceContext);

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds: any = new Set(listData.map((item: any) => item.user_id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    serviceManagerId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(serviceManagerId);
    } else {
      newSelectedRows.delete(serviceManagerId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const accept = () => {
    if (selectedUser) {
      // deletePlan(selectedUser.trip_id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data?.user_id)}
              onChange={(e) => handleRowCheckboxChange(e, data?.user_id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "Repair ID",
      field: "repair_id",
      key: "repair_id",
      sortField: "repair_id",
      body: (data: any) => (
        <Link to="#" className="text-link">
          {data?.repair_System?.repair_refid}
        </Link>
      ),
    },
    {
      header: "Type",
      field: "type",
      key: "type",
      sortField: "type",
      body: (data: any) => <div>{data?.repair_System?.type_of_repair}</div>,
    },
    {
      header: "Trip ID",
      field: "trip_refid",
      key: "trip_refid",
      sortField: "trip_refid",
    },
    {
      header: "Inspection ID",
      field: "inspection_refid",
      key: "inspection_refid",
      sortField: "inspection_refid",
    },
    {
      header: "Title & Complaints",
      field: "complaints",
      key: "complaints",
      sortField: "complaints",
      body: (data: any) => (
        <div className="trip-data">
          {data?.repair_System?.repair_name}
          <p>{data?.repair_System?.complaint}</p>
        </div>
      ),
    },
    {
      header: "Start-End Date",
      field: "start_date",
      key: "start_date",
      sortField: "start_date",
      body: (data: any) => (
        <div className="trip-data-date">
          {data?.repair_System?.repair_startdate != null ? (
            <p>
              <i className="ti ti-calendar-up" />{" "}
              {formatDate(data?.repair_System?.repair_startdate) || "-"}
            </p>
          ):("-")}
          {data?.repair_System?.repair_enddate != null ? (
            <p>
              <i className="ti ti-calendar-down" />{" "}
              {formatDate(data?.repair_System?.repair_enddate) || "-"}
            </p>
          ):("-")}
        </div>
      ),
    },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortField: "status",
      body: (data: any) => (
        <span
          className={`badge ${
            data?.repair_System?.repair_status === "Initiated"
              ? "status-initiated"
              : data?.repair_System?.repair_status === "Parts Ordered"
              ? "status-danger"
              : data?.repair_System?.repair_status === "In progress"
              ? "status-inprogress"
               : data?.repair_System?.repair_status === "Completed"
              ? "status-completed"
              : ""
          }`}
        >
          {data?.repair_System?.repair_status}
        </span>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (data: any) => {
        return (
          <>
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  to={routes.repairDetails.path}
                  className="dropdown-item"
                  onClick={() => dispatch(setRepairViewData(data))}
                >
                  <i className="feather icon-eye" /> View
                </Link>
                {/* <Link to="#" className="dropdown-item">
                  <i className="ti ti-tool" /> Add to Service
                </Link>
                <Link to="#" className="dropdown-item">
                  <i className="feather icon-edit" /> Edit
                </Link>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => handleDeleteClick(data)}
                >
                  <i className="feather icon-trash-2" /> Delete
                </Link> */}
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const getRepairList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.get_repairpaging.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.users);
        setTotalRecords(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRepairList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Repair System Tracking</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Repair System Tracking</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Sub Header */}
          <div className="sub-header">
            <div className="sub-header-content">
              <ul className="nav">
                <li>
                  <h4>All Service ({totalRecords})</h4>
                </li>
              </ul>
            </div>
          </div>
          {/* /Sub Header */}
          {/* Table */}
          <div className="card table-card">
            <div className="card-body">
              <div className="table-responsive prime-table">
                <PrimeDataTable
                  setSearchQuery={setSearchQuery}
                  column={columns}
                  data={listData}
                  rows={rows}
                  setRows={setRows}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalRecords={totalRecords}
                />
              </div>
            </div>
          </div>
          {/* /Table */}
        </div>
      </div>
      {/* /Page Wrapper */}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this Record?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
    </>
  );
};

export default RepairList;
