import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import {
  ApiServiceContext,
  routes,
  yup,
  yupResolver,
} from "../../../../utils/shared.module";
import { useForm, Controller } from "react-hook-form";
import { end_points } from "../../../../services/end_point/end_points";
import { toast } from "react-toastify";
import { clearEditData } from "../../../../core/redux/commonSlice";
import {
  validImageTypes,
  vehicleMaxLength,
  licence_plate_number,
  VEHICLE_MAKE_LENGTH,
  VIN_LENGTH,
  VEHICLE_MODAL_LENGTH,
  DESCRIPTION_LENGTH,
} from "../../../../utils/patterns/regex.pattern";
import { Dropdown } from "primereact/dropdown";

const AddEditVehicles = (props: any) => {
  const data = useSelector((state: any) => state.common.editData);
  const id = data?.vc_id;
  const path = window.location.pathname;
  const navigate = useNavigate();
  const { postData, putData } = useContext(ApiServiceContext);

  const [fileInfo, setFileInfo] = useState<{
    preview: string | null;
    fileName: string | null;
    fileSize: string | null;
  }>({
    preview: null,
    fileName: null,
    fileSize: null,
  });

  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
    dispatch(clearEditData());
    setFileInfo({});
  };

  const vehicleSchema = yup.object().shape({
    vehicle_name: yup.string().trim().required("Vehicle name is required"),
    license_plate: yup
      .string()
      .trim()
      .required("Licence plate number is required")
      .min(7, "Licence plate number must be 7 characters"),
    vin_number: yup
      .string()
      .trim()
      .required("VIN/SN is required")
      .min(17, "VIN/SN must be 17 characters"),
    year: yup.string().trim().required("Year is required"),
    make: yup
      .string()
      .trim()
      .max(50, "Make cannot exceed 50 characters")
      .required("Make is required"),
    model: yup.string().trim().required("Model is required"),
    vehicle_typeid: yup
      .object({
        name: yup.string().trim().required("Vehicle type is required"),
      })
      .nullable()
      .required("Vehicle type is required"),
    vehicle_description: yup
      .string()
      .trim()
      .max(255, "Vehicle description cannot exceed 255 characters")
      .required("Vehicle description is required"),
    vehicle_image: yup
      .mixed()
      .test("image.value", "Please upload an image", (value: any) => {
        if (value == "") {
          return false;
        } else return true;
      })
      .test(
        "image.type",
        "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
        (value: any) => {
          if (value == "") {
            return false;
          }
          if (!value || !value.length || typeof value === "string") {
            // Skip validation if the field is empty
            return true;
          }
          return value && validImageTypes?.includes(value[0]?.type);
        }
      )
      .test("fileSize", "File size is too large", (value: any) => {
        if (value == "") {
          return false;
        }
        if (!value || !value.length || typeof value === "string") {
          // Skip validation if the field is empty
          return true;
        }
        return value && value[0].size <= 4194304;
      })
      .required("Please upload an image!"),

    status: yup.boolean(),
  });
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(vehicleSchema),
    defaultValues: {
      vehicle_name: "",
      license_plate: "",
      vin_number: "",
      year: "",
      make: "",
      model: "",
      vehicle_typeid: null,
      vehicle_description: "",
      vehicle_image: null,
      status: true,
    },
  });

  useEffect(() => {
    if (id) {
      const type =
        props.vehicleType.find(
          (option: any) => option.code === data?.vehicle_typeid
        ) || null;
      setValue("vehicle_name", data.vehicle_name || "");
      setValue("license_plate", data.license_plate || "");
      setValue("vin_number", data.vin_number || "");
      setValue("year", data.year || "");
      setValue("make", data.make || "");
      setValue("model", data.model || "");
      setValue("vehicle_typeid", type || "");
      setValue("vehicle_description", data.vehicle_description || "");
      setValue("vehicle_image", data.vehicle_image || null);
      setValue("status", data.status === 1 ? true : false);
      if (data.vehicle_image) {
        setFileInfo({
          preview: data.vehicle_image,
        });
      }
    }
  }, [data, setValue]);

  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();
      formData.append("vehicle_name", data.vehicle_name);
      formData.append("license_plate", data.license_plate);
      formData.append("vin_number", data.vin_number);
      formData.append("year", data.year);
      formData.append("make", data.make);
      formData.append("model", data.model);
      formData.append("vehicle_typeid", data.vehicle_typeid.code);
      formData.append("vehicle_description", data.vehicle_description);

      if (data.vehicle_image) {
        formData.append("vehicle_image", data.vehicle_image);
      }
      if (id) {
        formData.append("vc_id", id);
      }
      const vehicle_status = data.status ? 1 : 2;
      formData.append("status", vehicle_status);

      const url = id
        ? end_points.update_truck.url
        : end_points.create_truck.url;

      const response = id
        ? await putData(url, formData)
        : await postData(url, formData);

      if (response?.status === 200) {
        toast.success(response?.data?.response?.responseMessage);
        dispatch(setAddCanvasSidebar(!offcanvas));
        dispatch(clearEditData());
        if (path === "/vehicles-details") {
          navigate(routes.vehiclesList.path);
        } else {
          props.successcall();
        }
      }
    } catch (error) {}
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileInfo({
        preview: URL.createObjectURL(file),
        fileName: file.name,
        fileSize: (file.size / 1024).toFixed(2),
      });
    }
  };

  const [selectedVehicle, setSelectedVehicle] = useState(null);

  return (
    <>
      {/* Vehicles Sidebar */}
      <div
        className={`truck-list-sidebar ${
          offcanvas ? "truck-list-sidebar-open" : ""
        }`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {id ? "Edit Vehicle" : "Add Vehicle"}
              </h4>
              <Link
                onClick={handleOffcanvas}
                to="#"
                className="clear-truck-list"
              >
                <i className="fas fa-xmark" />
              </Link>
            </div>
            <div className="card-body">
              <div className="truck-information">
                <h4>Vehicle Details</h4>
                <p>{!id ? "Please add details to save new vehicle" : ""}</p>
                <div className="truck-information-grid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-wrap">
                        <label>
                          Vehicle Name <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="vehicle_name"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              maxLength={vehicleMaxLength}
                              className="form-control"
                              placeholder="Enter Vehicle Name"
                              {...field}
                              onKeyDown={(e) => {
                                if (
                                  !/[a-zA-Z0-9\s]/.test(e.key) &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("vehicle_name");
                              }}
                              onBlur={() => trigger("vehicle_name")}
                            />
                          )}
                        />
                        {errors.vehicle_name && (
                          <small className="text-danger">
                            {errors.vehicle_name.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label>
                          Licence Plate Number{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="license_plate"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              maxLength={7} // US license plates are usually 1-7 characters long
                              className="form-control"
                              placeholder="Enter Number"
                              {...field}
                              onKeyDown={(e) => {
                                if (
                                  !/[a-zA-Z0-9]/.test(e.key) &&
                                  e.key !== "Backspace" &&
                                  e.key !== "Tab"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("license_plate");
                              }}
                              onBlur={() => trigger("license_plate")}
                            />
                          )}
                        />
                        {errors.license_plate && (
                          <small className="text-danger">
                            {errors.license_plate.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label>
                          VIN <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="vin_number"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Number"
                              maxLength={VIN_LENGTH}
                              {...field}
                              onKeyDown={(e) => {
                                if (
                                  !/^[A-HJ-NPR-Z0-9]$/i.test(e.key) &&
                                  e.key !== "Backspace" &&
                                  e.key !== "Tab"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("vin_number");
                              }}
                              onBlur={() => trigger("vin_number")}
                            />
                          )}
                        />
                        {errors.vin_number && (
                          <small className="text-danger">
                            {errors.vin_number.message}
                          </small>
                        )}
                        <div className="form-wrap-alert">
                          <p>
                            <i className="feather icon-alert-circle" />
                            Vehicle Identification Number
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label>
                          Year <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="year"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Year"
                              maxLength={4}
                              {...field}
                              onKeyDown={(e) => {
                                if (
                                  !/[0-9]/.test(e.key) &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                let yearValue = e.target.value;
                                const currentYear = new Date().getFullYear();

                                // Limit year to the current year if necessary
                                if (
                                  yearValue.length === 4 &&
                                  parseInt(yearValue) > currentYear
                                ) {
                                  yearValue = currentYear?.toString();
                                }

                                field.onChange(yearValue); // Update field value
                                trigger("year"); // Trigger validation
                              }}
                              onBlur={() => trigger("year")} // Trigger validation on blur
                            />
                          )}
                        />
                        {errors.year && (
                          <small className="text-danger">
                            {errors.year.message}
                          </small>
                        )}
                        <div className="form-wrap-alert">
                          <h6>Eg. 1999, 2021, etc.</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label>
                          Make <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="make"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              maxLength={VEHICLE_MAKE_LENGTH}
                              className="form-control"
                              placeholder="Enter Make"
                              onKeyDown={(e) => {
                                if (
                                  !/[a-zA-Z0-9\s]/.test(e.key) &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("make");
                              }}
                              onBlur={() => trigger("make")}
                            />
                          )}
                        />
                        {errors.make && (
                          <small className="text-danger">
                            {errors.make.message}
                          </small>
                        )}
                        <div className="form-wrap-alert">
                          <h6>Eg. BMW, Ford etc.</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label>
                          Model <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="model"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              maxLength={VEHICLE_MODAL_LENGTH}
                              className="form-control"
                              placeholder="Enter Modal"
                              {...field}
                              onKeyDown={(e) => {
                                if (
                                  !/[a-zA-Z0-9\s]/.test(e.key) &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("model");
                              }}
                              onBlur={() => trigger("model")}
                            />
                          )}
                        />
                        {errors.model && (
                          <small className="text-danger">
                            {errors.model.message}
                          </small>
                        )}
                        <div className="form-wrap-alert">
                          <h6>Eg. CV6, Trucks, etc.</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label>
                          Type <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="vehicle_typeid"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              filter
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                                setSelectedVehicle(e.value);
                              }}
                              onBlur={() => trigger("vehicle_typeid")}
                              options={props.vehicleType}
                              optionLabel="name"
                              placeholder="Select"
                              className="w-100"
                            />
                          )}
                        />
                        {errors.vehicle_typeid && (
                          <small className="text-danger">
                            {errors.vehicle_typeid.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-wrap">
                        <label>
                          Vehicle Description{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="vehicle_description"
                          control={control}
                          render={({ field }) => (
                            <textarea
                              maxLength={DESCRIPTION_LENGTH}
                              className="form-control"
                              placeholder="Enter Vehicle Description"
                              {...field}
                              onBlur={() => trigger("vehicle_description")}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("vehicle_description");
                              }}
                            />
                          )}
                        />
                        {errors.vehicle_description && (
                          <small className="text-danger">
                            {errors.vehicle_description.message}
                          </small>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-6">
                      <div className="form-wrap mb-0">
                        <div className="upload-info mb-3">
                          <label>
                            Vehicle Image <span className="text-danger">*</span>
                          </label>
                          <p>
                            <i className="feather icon-alert-circle" />
                            Maximum File size 2MB &amp; PNG, JPEG, SVG
                          </p>
                          <label className="choose-file">
                            <Controller
                              name="vehicle_image"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="file"
                                  accept="image/jpeg, image/png, image/svg+xml, image/jpg"
                                  className="form-control"
                                  onChange={(e) => {
                                    field.onChange(e.target.files?.[0]);
                                    handleFileChange(e);
                                  }}
                                />
                              )}
                            />
                            <span>
                              <i className="feather icon-upload" /> Choose a
                              File to Upload
                            </span>
                          </label>
                          {errors.vehicle_image && (
                            <small className="text-danger">
                              {errors.vehicle_image.message}
                            </small>
                          )}
                          {fileInfo.preview && (
                            <div
                              className={`upload-grid ${
                                id ? "edit-upload" : ""
                              }`}
                            >
                              <div className="upload-img">
                                <img
                                  src={fileInfo.preview}
                                  alt="Vehicle Preview"
                                />
                              </div>
                              <div className="upload-text">
                                <h6>{fileInfo.fileName}</h6>
                                <p>{fileInfo.fileSize}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sidebar-switch">
                  <h6>Status</h6>
                  <div className="toggles">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={field.value === true ? true : false}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                          <div className="slider round">
                            <span className="on">
                              <i className="ti ti-check"></i>
                            </span>
                            <span className="off">
                              <i className="ti ti-x"></i>
                            </span>
                          </div>
                        </label>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer canvas-btn">
              <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
                Cancel
              </Link>
              <button className="btn btn-add" type="submit">
                {id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* /Vehicles Sidebar */}
    </>
  );
};

export default AddEditVehicles;
