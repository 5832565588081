import React, { useContext, useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import PhoneInputWithRef from "../../../../utils/phoneInputWithRef";
import { clearEditData } from "../../../../core/redux/commonSlice";
import {
  email,
  onlyAlphabet,
  validMessage,
  nameMaxLength,
  emailMaxLength,
  validImageTypes,
  licenceMaxLength,
  addressMaxLength,
  onlyNumber,
  alphaNumeric,
  drivingLicence,
} from "../../../../utils/patterns/regex.pattern";
import { Link } from "react-router-dom";
import parsePhoneNumberFromString from "libphonenumber-js";
import { validatePhoneNumber } from "../../../../utils/constant";

const validationSchema = Yup.object().shape({
  fullname: Yup.string().trim()
    .required("Full name is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .trim()
    .required("Email is required")
    .matches(email, "Please enter a valid email"),
  contact_number: Yup.string()
    .trim()
    .required("Contact number is required")
    .test("is-valid-phone", "Invalid phone number", (value) =>
      validatePhoneNumber(value)
    ),
  dob: Yup.string().required("Date of birth is required"),
  licence_number: Yup.string().trim()
    .required("Driving licence is required"),
  address: Yup.string().trim().required("Address is required"),
  profile_image: Yup.mixed()
    .test("image.value", "Please upload an image", (value: any) => {
      if (value == "") {
        return false;
      } else return true;
    })
    .test(
      "image.type",
      "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
      (value: any) => {
        if (value == "") {
          return false;
        }
        if (!value || !value.length || typeof value === "string") {
          // Skip validation if the field is empty
          return true;
        }
        return value && validImageTypes?.includes(value[0]?.type);
      }
    )
    .test("fileSize", "File size is too large", (value: any) => {
      if (value == "") {
        return false;
      }
      if (!value || !value.length || typeof value === "string") {
        // Skip validation if the field is empty
        return true;
      }
      return value && value[0].size <= 4194304;
    })
    .required("Please upload an image!"),

  e_signature_image: Yup.mixed()
    .test("image.value", "Please upload an image", (value: any) => {
      if (value == "") {
        return false;
      } else return true;
    })
    .test(
      "image.type",
      "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
      (value: any) => {
        if (value == "") {
          return false;
        }
        if (!value || !value.length || typeof value === "string") {
          // Skip validation if the field is empty
          return true;
        }
        return value && validImageTypes?.includes(value[0]?.type);
      }
    )
    .test("fileSize", "File size is too large", (value: any) => {
      if (value == "") {
        return false;
      }
      if (!value || !value.length || typeof value === "string") {
        // Skip validation if the field is empty
        return true;
      }
      return value && value[0].size <= 4194304;
    })
    .required("E-signature is required"),
  status: Yup.boolean(),
});

const AddEditDrivers = ({ successcall = () => {} }) => {
  const [date1, setDate1] = useState<Date | null>(null);

  const data = useSelector((state: any) => state.common.editData);
  const driver_id = data?.user_id;
  const [selectedCountry, setSelectedCountry] = useState<any>("");

  type PreviewState = {
    image: string | null;
    fileName: string | null;
    fileSize: string | null;
  };

  type Esign = {
    image: string | null;
    fileName: string | null;
    fileSize: string | null;
  };

  const [preview, setPreview] = useState<PreviewState>({
    image: null,
    fileName: "",
    fileSize: "",
  });
  const [previewEsign, setPreviewEsign] = useState<Esign>({
    image: null,
    fileName: "",
    fileSize: "",
  });

  const { postData, putData } = useContext(ApiServiceContext);

  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fullname: "",
      email: "",
      contact_number: "",
      dob: null,
      licence_number: "",
      address: "",
      profile_image: null,
      e_signature_image: null,
      status: true,
    },
  });

  useEffect(() => {
    if (driver_id) {
      // Set form values
      setValue("fullname", data.firstname || "");
      setValue("email", data.email || "");
      setValue("contact_number", data.phone || "");
      setValue("dob", new Date(data.dateofbirth) || null);
      setValue("licence_number", data.driving_licenceno || "");
      setDate1(new Date(data.dateofbirth));

      setValue("address", data.address || "");
      setValue("status", data.status === 1 ? true : false);
      setValue("profile_image", data.profile_Image || "");
      setValue("e_signature_image", data.profile_Signature || "");

      // Handle profile image
      if (data.profile_Image) {
        setPreview({
          image: data.profile_Image,
          fileName: "",
          fileSize: "",
        });
      }

      // Handle e-signature image
      if (data.profile_Signature) {
        setPreviewEsign({
          image: data.profile_Signature,
          fileName: "",
          fileSize: "",
        });
      }
    }
    setValue("status", true);
  }, [data, setValue]);

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
    dispatch(clearEditData());
    setPreviewEsign({});
    setPreview({});
  };

  const formatDate = (date: any) => {
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0"); // Get the day of the month
    const year = date.getFullYear(); // Get the full year

    return `${month}/${day}/${year}`;
  };

  const onSubmit = async (data: any) => {
    const date = new Date(data.dob);
    const formated_date = formatDate(date);
    const phoneNumber = parsePhoneNumberFromString(
      data.contact_number,
      selectedCountry.toUpperCase()
    );
    const formattedPhone = phoneNumber
      ? phoneNumber.formatInternational()
      : data.contact_number;

    try {
      const formData = new FormData();
      formData.append("firstname", data.fullname);
      formData.append("email", data.email);
      formData.append("phone", formattedPhone);
      formData.append("dateofbirth", formated_date);
      formData.append("driving_licenceno", data.licence_number);

      formData.append("profile_image", data.profile_image);
      formData.append("profile_signature", data.e_signature_image);
      const statusValue = data.status ? "1" : "2";
      formData.append("status", statusValue);
      formData.append("roles", 2);
      formData.append("address", data.address);

      if (driver_id) {
        formData.append("user_id", driver_id);
      }

      const urls = driver_id
        ? end_points.update_driver.url
        : end_points.create_driver.url;
      const response = driver_id
        ? await putData(urls, formData)
        : await postData(urls, formData);
      if (response.status === 200) {
        toast.success(response?.data?.response?.responseMessage);
        reset();
        dispatch(setAddCanvasSidebar(!offcanvas));
        successcall();
        dispatch(clearEditData());
      }
    } catch (e) {
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setValue("profile_image", file, { shouldValidate: true });

      setPreview({
        image: URL.createObjectURL(file),
        fileName: file.name,
        fileSize: (file.size / 1024).toFixed(2), // File size in KB
      });
    }
  };

  const handleFileChangeEsign = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue("e_signature_image", file, { shouldValidate: true });
      setPreviewEsign({
        image: URL.createObjectURL(file),
        fileName: file.name,
        fileSize: (file.size / 1024).toFixed(2), // File size in KB
      });
    }
  };

  return (
    <div
      className={`truck-list-sidebar ${
        offcanvas ? "truck-list-sidebar-open" : ""
      }`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {driver_id ? "Edit" : "Add New"} Driver
            </h4>
            <Link to="#" className="clear-truck-list" onClick={handleOffcanvas}>
              <i className="fas fa-xmark"></i>
            </Link>
          </div>
          <div className="card-body">
            <div className="sidebar-sub-header">
              <h4>Driver Profile</h4>
              <p>
                {!driver_id
                  ? "Please add details to save the driver profile"
                  : ""}
              </p>
            </div>
            <div className="truck-information">
              <div className="truck-information-grid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label>
                        Full Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="fullname"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className="form-control"
                            maxLength={nameMaxLength}
                            placeholder="Enter Full Name"
                            onKeyDown={(e) => {
                              if (
                                !/[a-zA-Z\s]/.test(e.key) &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("fullname");
                            }}
                            onBlur={() => trigger("fullname")}
                          />
                        )}
                      />
                      {errors.fullname && (
                        <small className="text-danger">
                          {errors.fullname.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label>
                        Email <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            maxLength={emailMaxLength}
                            className="form-control"
                            placeholder="Enter Email"
                            onBlur={() => trigger("email")}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("email");
                            }}
                          />
                        )}
                      />
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label>
                        Contact Number <span className="text-danger">*</span>
                      </label>
                      <Controller
                        defaultValue=""
                        name="contact_number"
                        control={control}
                        render={({ field }) => (
                          <PhoneInputWithRef
                            country={"us"}
                            placeholder="Enter Contact Number"
                            {...field}
                            onChange={(value: string, country: any) => {
                              field.onChange(value);
                              setSelectedCountry(country.countryCode);
                              trigger("contact_number");
                            }}
                            onBlur={() => trigger("contact_number")}
                          />
                        )}
                      />
                      {errors.contact_number && (
                        <small className="text-danger">
                          {errors.contact_number.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label>
                        Date of Birth <span className="text-danger">*</span>
                      </label>
                      <div className="form-wrap-icon">
                        <Controller
                          name="dob"
                          control={control}
                          render={({ field }) => (
                            <Calendar
                              className="datetimepicker"
                              value={date1}
                              placeholder="MM/DD/YYYY"
                              maxDate={new Date()}
                              onChange={(e: any) => {
                                setDate1(e.value);
                                field.onChange(e.value);
                                trigger("dob");
                              }}
                              onBlur={() => trigger("dob")}
                            />
                          )}
                        />
                        <i className="ti ti-calendar-share" />
                      </div>
                      {errors.dob && (
                        <small className="text-danger">
                          {errors.dob.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label>
                        Driving Licence <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="licence_number"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            maxLength={licenceMaxLength}
                            className="form-control"
                            placeholder="Enter Licence Number"
                            onBlur={() => trigger("licence_number")}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("licence_number");
                            }}
                            onKeyDown={(e) => {
                              if (
                                !/^[a-zA-Z0-9]$/i.test(e.key) &&
                                e.key !== "Backspace" &&
                                e.key !== "Tab"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        )}
                      />
                      {errors.licence_number && (
                        <small className="text-danger">
                          {errors.licence_number.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label>
                        Address <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            maxLength={addressMaxLength}
                            className="form-control"
                            placeholder="Enter Full Address"
                            onBlur={() => trigger("address")}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("address");
                            }}
                          />
                        )}
                      />
                      {errors.address && (
                        <small className="text-danger">
                          {errors.address.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <div className="upload-info">
                        <label>
                          Profile Image <span className="text-danger">*</span>
                        </label>
                        <p>
                          <i className="feather icon-alert-circle" /> Maximum
                          File size 2MB &amp; PNG, JPEG, SVG
                        </p>
                        <label className="choose-file">
                          <input
                            type="file"
                            accept="image/jpeg, image/png, image/svg+xml, image/jpg"
                            onChange={(e) =>
                              handleFileChange(e, "profile_image")
                            }
                          />
                          <span>
                            <i className="feather icon-upload" /> Choose to File
                            Upload
                          </span>
                        </label>
                        {errors.profile_image && (
                          <small className="text-danger">
                            {errors.profile_image.message}
                          </small>
                        )}
                        {preview.image && (
                          <div
                            className={`upload-grid ${
                              driver_id ? "edit-upload" : ""
                            }`}
                          >
                            <div className="upload-img">
                              <img src={preview.image} alt="Profile" />
                            </div>
                            <div className="upload-text">
                              <h6>{preview.fileName}</h6>
                              <p>{preview.fileSize}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <div className="upload-info">
                        <label>
                          Upload E-Signature{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <p>
                          <i className="feather icon-alert-circle" /> Maximum
                          File size 2MB &amp; PNG, JPEG, SVG
                        </p>
                        <label className="choose-file">
                          <input type="file" accept="image/jpeg, image/png, image/svg+xml, image/jpg" onChange={handleFileChangeEsign} />
                          <span>
                            <i className="feather icon-upload" /> Choose to File
                            Upload
                          </span>
                        </label>
                        {errors.e_signature_image && (
                          <small className="text-danger">
                            {errors.e_signature_image.message}
                          </small>
                        )}

                        {previewEsign.image && (
                          <div className="upload-signature">
                            <img src={previewEsign.image} alt="Signature" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sidebar-switch">
                <h6>Status</h6>
                <div className="toggles">
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                        <div className="slider round">
                          <span className="on">
                            <i className="ti ti-check" />
                          </span>
                          <span className="off">
                            <i className="ti ti-x" />
                          </span>
                        </div>
                      </label>
                    )}
                  />
                  {errors.status && (
                    <small className="text-danger">
                      {errors.status.message}
                    </small>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer sidebar-btn">
            <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
              Cancel
            </Link>
            <button type="submit" className="btn btn-add">
              {driver_id ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditDrivers;
