import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { yup } from "../../../../utils/shared.module";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import { toast } from "react-toastify";
import {
  email,
  nameMaxLength,
  emailMaxLength,
  validImageTypes,
} from "../../../../utils/patterns/regex.pattern";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import PhoneInputWithRef from "../../../../utils/phoneInputWithRef";
import { clearEditData } from "../../../../core/redux/commonSlice";
import parsePhoneNumberFromString from "libphonenumber-js";
import { validatePhoneNumber } from "../../../../utils/constant";

const FleetmanagerAddEdit = ({ successcall = () => {} }) => {
  const data = useSelector((state: any) => state.common.editData);
  const fleet_id = data?.user_id;

  const { postData, putData } = useContext(ApiServiceContext);
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const user_id = useSelector((state: any) => state.login.userDetails.id);

  type PreviewState = {
    image: string | null;
    fileName: string | null;
    fileSize: string | null;
  };

  type Esign = {
    image: string | null;
    fileName: string | null;
    fileSize: string | null;
  };

  const [preview, setPreview] = useState<PreviewState>({
    image: null,
    fileName: "",
    fileSize: "",
  });
  const [previewEsign, setPreviewEsign] = useState<Esign>({
    image: null,
    fileName: "",
    fileSize: "",
  });

  const validationSchema = Yup.object().shape({
    fullname: Yup.string().trim()
      .required("Full name is required"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .trim()
      .required("Email is required")
      .matches(email, "Please enter a valid email"),
    contact_phone: yup
      .string()
      .trim()
      .required("Contact number is required")
      .test("is-valid-phone", "Invalid phone number", (value) =>
        validatePhoneNumber(value)
      ),
    profile_image: yup
      .mixed()
      .test("image.value", "Please upload an image", (value: any) => {
        if (value == "") {
          return false;
        } else return true;
      })
      .test(
        "image.type",
        "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
        (value: any) => {
          if (value == "") {
            return false;
          }
          if (!value || !value.length || typeof value === "string") {
            // Skip validation if the field is empty
            return true;
          }
          return value && validImageTypes?.includes(value[0]?.type);
        }
      )
      .test("fileSize", "File size is too large", (value: any) => {
        if (value == "") {
          return false;
        }
        if (!value || !value.length || typeof value === "string") {
          // Skip validation if the field is empty
          return true;
        }
        return value && value[0].size <= 4194304;
      })
      .required("Profile image is required"),

    e_signature_image: yup
      .mixed()
      .test("image.value", "Please upload an image", (value: any) => {
        if (value == "") {
          return false;
        } else return true;
      })
      .test(
        "image.type",
        "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
        (value: any) => {
          if (value == "") {
            return false;
          }
          if (!value || !value.length || typeof value === "string") {
            // Skip validation if the field is empty
            return true;
          }
          return value && validImageTypes?.includes(value[0]?.type);
        }
      )
      .test("fileSize", "File size is too large", (value: any) => {
        if (value == "") {
          return false;
        }
        if (!value || !value.length || typeof value === "string") {
          // Skip validation if the field is empty
          return true;
        }
        return value && value[0].size <= 4194304;
      })
      .required("E-signature is required"),
    status: Yup.boolean(),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fullname: "",
      email: "",
      contact_phone: "",
      profile_image: null,
      e_signature_image: null,
      status: true,
    },
  });

  useEffect(() => {
    if (fleet_id) {
      setValue("fullname", data.firstname || "");
      setValue("email", data.email || "");
      setValue("contact_phone", data.phone || "");
      setValue("status", data.status === 1 ? true : false);
      setValue("profile_image", data.profile_Image || "");
      setValue("e_signature_image", data.profile_Signature || "");

      if (data.profile_Image) {
        setPreview({
          image: data.profile_Image,
        });
      }

      if (data.profile_Signature) {
        setPreviewEsign({
          image: data.profile_Signature,
        });
      }
    } else {
      reset();
    }
  }, [data, reset, setValue]);

  const onSubmit = async (data: any) => {
    const phoneNumber = parsePhoneNumberFromString(
      data.contact_phone,
      selectedCountry.toUpperCase()
    );
    const formattedPhone = phoneNumber
      ? phoneNumber.formatInternational()
      : data.contact_phone;

    try {
      const formData = new FormData();
      formData.append("firstname", data.fullname);
      formData.append("email", data.email);
      formData.append("phone", formattedPhone);
      formData.append("profile_image", data.profile_image);
      formData.append("profile_signature", data.e_signature_image);
      const statusValue = data.status ? "1" : "2";
      formData.append("status", statusValue);
      formData.append("created_by", user_id);
      formData.append("roles", 3);

      if (fleet_id) {
        formData.append("user_id", fleet_id);
      }

      const urls = fleet_id
        ? end_points.update_fleetmanager.url
        : end_points.create_fleetmanager.url;
      const response = fleet_id
        ? await putData(urls, formData)
        : await postData(urls, formData);
      if (response.status === 200) {
        toast.success(response?.data?.response?.responseMessage);
        reset();
        setPreview({});
        setPreviewEsign({});
        setValue("fullname", "");
        setValue("email", "");
        setValue("contact_phone", "");

        setValue("status", true);
        dispatch(setAddCanvasSidebar(!offcanvas));
        dispatch(clearEditData());
        successcall();
      }
    } catch (e) {
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue("profile_image", file, { shouldValidate: true });

      setPreview({
        image: URL.createObjectURL(file),
        fileName: file.name,
        fileSize: (file.size / 1024).toFixed(2), // File size in KB
      });
    }
  };

  const handleFileChangeEsign = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue("e_signature_image", file, { shouldValidate: true });
      setPreviewEsign({
        image: URL.createObjectURL(file),
        fileName: file.name,
        fileSize: (file.size / 1024).toFixed(2), // File size in KB
      });
    }
  };

  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
    dispatch(clearEditData());
    setPreviewEsign({});
    setPreview({});
  };

  return (
    <>
      <div
        className={`truck-list-sidebar ${
          offcanvas ? "truck-list-sidebar-open" : ""
        }`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="card-header">
              <div className="offcanvas-header-edit">
                <h5 className="mt-0 card-title heading-custom">
                  {" "}
                  {fleet_id ? "Edit" : "Add New"} Fleet Manager
                </h5>
              </div>
              <Link
                to="#"
                className="clear-truck-list"
                onClick={handleOffcanvas}
              >
                <i className="fas fa-xmark" />
              </Link>
            </div>
            <div className="card-body">
              <div className="sidebar-sub-header">
                <h4>Fleet Manager Profile</h4>
                <p>
                  {!fleet_id
                    ? "Please add details to save fleet manager profile"
                    : ""}
                </p>
              </div>
              <div className="truck-information">
                <div className="truck-information-grid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-wrap">
                        <label>
                          Full Name <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="fullname"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              maxLength={nameMaxLength}
                              className="form-control"
                              placeholder="Enter Full Name"
                              onKeyDown={(e) => {
                                if (
                                  !/[a-zA-Z\s]/.test(e.key) &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onBlur={() => trigger("fullname")}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("fullname");
                              }}
                            />
                          )}
                        />
                        {errors.fullname && (
                          <small className="text-danger">
                            {errors.fullname.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              maxLength={emailMaxLength}
                              className="form-control"
                              placeholder="Enter Email"
                              onBlur={() => trigger("email")}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("email");
                              }}
                            />
                          )}
                        />
                        {errors.email && (
                          <small className="text-danger">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label>
                          Contact Number <span className="text-danger">*</span>
                        </label>
                        <Controller
                          defaultValue=""
                          name="contact_phone"
                          control={control}
                          render={({ field }) => (
                            <PhoneInputWithRef
                              country={"us"}
                              placeholder="Enter Contact Phone Number"
                              {...field}
                              onChange={(value: string, country: any) => {
                                field.onChange(value);
                                setSelectedCountry(country.countryCode);
                                trigger("contact_phone");
                              }}
                              onBlur={() => trigger("contact_phone")}
                            />
                          )}
                        />
                        {errors.contact_phone && (
                          <small className="text-danger">
                            {errors.contact_phone.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <div className="upload-info">
                          <label>
                            Profile Image <span className="text-danger">*</span>
                          </label>
                          <p>
                            <i className="feather icon-alert-circle" /> Maximum
                            File size 2MB &amp; PNG, JPEG, SVG
                          </p>
                          <label className="choose-file">
                            <input
                              type="file"
                              accept="image/jpeg, image/png, image/svg+xml, image/jpg"
                              onChange={(e) =>
                                handleFileChange(e, "profile_image")
                              }
                            />
                            <span>
                              <i className="feather icon-upload" /> Choose to
                              File Upload
                            </span>
                          </label>
                          {errors.profile_image && (
                            <small className="text-danger">
                              {errors.profile_image.message}
                            </small>
                          )}
                          {preview.image && (
                            <div
                              className={`upload-grid ${
                                fleet_id ? "edit-upload" : ""
                              }`}
                            >
                              <div className="upload-img">
                                <img src={preview.image} alt="Profile" />
                              </div>
                              <div className="upload-text">
                                <h6>{preview.fileName}</h6>
                                <p>{preview.fileSize}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <div className="upload-info">
                          <label>
                            Upload E-Signature{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <p>
                            <i className="feather icon-alert-circle" /> Maximum
                            File size 2MB &amp; PNG, JPEG, SVG
                          </p>
                          <label className="choose-file">
                            <input
                              type="file"
                              accept="image/jpeg, image/png, image/svg+xml, image/jpg"
                              onChange={(e) =>
                                handleFileChangeEsign(e, "e_signature_image")
                              }
                            />
                            <span>
                              <i className="feather icon-upload" /> Choose to
                              File Upload
                            </span>
                          </label>
                          {errors.e_signature_image && (
                            <small className="text-danger">
                              {errors.e_signature_image.message}
                            </small>
                          )}

                          {previewEsign.image && (
                            <div className="upload-signature">
                              <img src={previewEsign.image} alt="Signature" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sidebar-switch">
                  <h6>Status</h6>
                  <div className="toggles">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={field.value === true ? true : false}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                          <div className="slider round">
                            <span className="on">
                              <i className="ti ti-check" />
                            </span>
                            <span className="off">
                              <i className="ti ti-x" />
                            </span>
                          </div>
                        </label>
                      )}
                    />
                    {errors.status && (
                      <small className="text-danger">
                        {errors.status.message}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer canvas-btn">
              <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
                Cancel
              </Link>
              <button type="submit" className="btn btn-add">
                {fleet_id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default FleetmanagerAddEdit;
