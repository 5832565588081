import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { toast } from "react-toastify";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../utils/shared.module";
import {
  email,
  nameMaxLength,
  validImageTypes,
  emailMaxLength,
} from "../../../../utils/patterns/regex.pattern";
import PhoneInputWithRef from "../../../../utils/phoneInputWithRef";
import { clearEditData } from "../../../../core/redux/commonSlice";
import parsePhoneNumberFromString from "libphonenumber-js";
import { validatePhoneNumber } from "../../../../utils/constant";

const AddEditServiceManager = ({ successcall = () => {} }) => {
  const data = useSelector((state: any) => state.common.editData);
  const id = data?.user_id;

  const { postData, putData } = useContext(ApiServiceContext);
  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
    dispatch(clearEditData());
  };
  const [selectedCountry, setSelectedCountry] = useState<any>("");

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().trim()
      .required("Full name is required"),
    email: Yup.string()
      .email("Please enter a valid email")
      .trim()
      .required("Email is required")
      .matches(email, "Please enter a valid email"),
    phone: Yup.string()
      .trim()
      .required("Contact number is required")
      .test("is-valid-phone", "Invalid phone number", (value) =>
        validatePhoneNumber(value)
      ),

    profile_Image: Yup.mixed()
      .test("image.value", "Please upload an image", (value: any) => {
        if (value == "") {
          return false;
        } else return true;
      })
      .test(
        "image.type",
        "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
        (value: any) => {
          if (value == "") {
            return false;
          }
          if (!value || !value.length || typeof value === "string") {
            // Skip validation if the field is empty
            return true;
          }
          return value && validImageTypes?.includes(value[0]?.type);
        }
      )
      .test("fileSize", "File size is too large", (value: any) => {
        if (value == "") {
          return false;
        }
        if (!value || !value.length || typeof value === "string") {
          // Skip validation if the field is empty
          return true;
        }
        return value && value[0].size <= 4194304;
      })
      .required("Profile image is required"),

    profile_signature: Yup.mixed()
      .test("image.value", "Please upload an image", (value: any) => {
        if (value == "") {
          return false;
        } else return true;
      })
      .test(
        "image.type",
        "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
        (value: any) => {
          if (value == "") {
            return false;
          }
          if (!value || !value.length || typeof value === "string") {
            // Skip validation if the field is empty
            return true;
          }
          return value && validImageTypes?.includes(value[0]?.type);
        }
      )
      .test("fileSize", "File size is too large", (value: any) => {
        if (value == "") {
          return false;
        }
        if (!value || !value.length || typeof value === "string") {
          // Skip validation if the field is empty
          return true;
        }
        return value && value[0].size <= 4194304;
      })
      .required("Signature image is required"),

    status: Yup.boolean(),
  });
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstname: "",
      email: "",
      phone: "",
      profile_Image: null,
      profile_signature: null,
      status: true,
    },
  });
  const [profileImageInfo, setProfileImageInfo] = useState<{
    preview: string | null;
    fileName: string | null;
    fileSize: string | null;
  }>({
    preview: null,
    fileName: null,
    fileSize: null,
  });

  const [signatureInfo, setSignatureInfo] = useState<{
    preview: string | null;
    fileName: string | null;
    fileSize: string | null;
  }>({
    preview: null,
    fileName: null,
    fileSize: null,
  });

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "profile" | "signature"
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileInfo = {
        preview: URL.createObjectURL(file),
        fileName: file.name,
        fileSize: (file.size / 1024).toFixed(2),
      };

      if (type === "profile") {
        setProfileImageInfo(fileInfo);
      } else if (type === "signature") {
        setSignatureInfo(fileInfo);
      }
    }
  };
  useEffect(() => {
    if (id) {
      setValue("firstname", data.firstname || "");
      setValue("email", data.email || "");
      setValue("phone", data.phone || "");
      setValue("status", data.status === 1 ? true : false);
      setValue("profile_Image", data.profile_Image || "");
      setValue("profile_signature", data.profile_Signature || "");
    }
    if (data?.profile_Image) {
      setProfileImageInfo({
        preview: data?.profile_Image,
      });
    }
    if (data?.profile_Signature) {
      setSignatureInfo({
        preview: data?.profile_Signature,
      });
    }
  }, [data, setValue]);

  const onSubmit = async (data: any) => {
    const phoneNumber = parsePhoneNumberFromString(
      data.phone,
      selectedCountry.toUpperCase()
    );
    const formattedPhone = phoneNumber
      ? phoneNumber.formatInternational()
      : data.phone;
    try {
      const formData = new FormData();
      formData.append("firstname", data.firstname);
      formData.append("email", data.email);
      formData.append("phone", formattedPhone);
      formData.append("profile_Image", data.profile_Image);
      formData.append("profile_signature", data.profile_signature);
      formData.append("roles", 4);
      const service_status = data.status ? 1 : 2;
      formData.append("status", service_status);
      if (data.profile_Image) {
        formData.append("profile_Image", data.profile_Image);
      }
      if (data.profile_signature) {
        formData.append("profile_signature", data.profile_signature);
      }
      if (id) {
        formData.append("user_id", id);
      }
      const url = id
        ? end_points.update_service.url
        : end_points.create_service.url;

      const response = id
        ? await putData(url, formData)
        : await postData(url, formData);

      if (response?.status === 200) {
        toast.success(response?.data?.response?.responseMessage);
        dispatch(setAddCanvasSidebar(!offcanvas));
        dispatch(clearEditData());
        successcall();
      } else {
        toast.error("Something went wrong, please try again.");
      }
    } catch (error) {
      console.error("Submission error:", error);
      toast.error("Error updating service manager.");
    }
  };
  const resetForm = () => {
    setValue("firstname", "");
    setValue("email", "");
    setValue("phone", "");
    setValue("status", true);
  };

  return (
    <>
      <div
        className={`truck-list-sidebar ${
          offcanvas ? "truck-list-sidebar-open" : ""
        }`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {id ? "Edit Service Manager" : "Add Service Manager"}
              </h4>
              <Link
                to="#"
                className="clear-truck-list"
                onClick={handleOffcanvas}
              >
                <i className="fas fa-xmark" />
              </Link>
            </div>
            <div className="card-body">
              <div className="sidebar-sub-header">
                <h4>Service Manager Profile</h4>
                <p>
                  {!id
                    ? "Please add details to save new service manager profile"
                    : ""}
                </p>
              </div>
              <div className="truck-information">
                <div className="truck-information-grid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-wrap">
                        <label>
                          Full Name <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="firstname"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              maxLength={nameMaxLength}
                              placeholder="Enter Full Name"
                              {...field}
                              onKeyDown={(e) => {
                                if (
                                  !/[a-zA-Z\s]/.test(e.key) &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("firstname");
                              }}
                              onBlur={() => trigger("firstname")}
                            />
                          )}
                        />
                        {errors.firstname && (
                          <small className="text-danger">
                            {errors.firstname.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <input
                              type="email"
                              maxLength={emailMaxLength}
                              className="form-control"
                              placeholder="Enter Email"
                              {...field}
                              onBlur={() => trigger("email")}
                            />
                          )}
                        />
                        {errors.email && (
                          <small className="text-danger">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label>
                          Contact Number <span className="text-danger">*</span>
                        </label>
                        <Controller
                          defaultValue=""
                          name="phone"
                          control={control}
                          render={({ field }) => (
                            <PhoneInputWithRef
                              country={"us"}
                              placeholder="Enter Contact Phone Number"
                              {...field}
                              onChange={(value: string, country: any) => {
                                field.onChange(value);
                                setSelectedCountry(country.countryCode);
                                trigger("phone");
                              }}
                              onBlur={() => trigger("phone")}
                            />
                          )}
                        />
                        {errors.phone && (
                          <small className="text-danger">
                            {errors.phone.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <div className="upload-info">
                          <label>
                            Profile Image <span className="text-danger">*</span>
                          </label>
                          <p>
                            <i className="feather icon-alert-circle" /> Maximum
                            File size 2MB & PNG, JPEG, SVG
                          </p>
                          <label className="choose-file">
                            <Controller
                              name="profile_Image"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="file"
                                  accept="image/jpeg, image/png, image/svg+xml, image/jpg"
                                  className="form-control"
                                  onChange={(e) => {
                                    field.onChange(e.target.files?.[0]);
                                    handleFileChange(e, "profile");
                                  }}
                                />
                              )}
                            />
                            <span>
                              <i className="feather icon-upload" /> Choose to
                              File Upload
                            </span>
                          </label>
                          {errors.profile_Image && (
                            <small className="text-danger">
                              {errors.profile_Image.message}
                            </small>
                          )}
                          {profileImageInfo.preview && (
                            <div
                              className={`upload-grid ${
                                id ? "edit-upload" : ""
                              }`}
                            >
                              <div className="upload-img">
                                <img
                                  src={profileImageInfo.preview}
                                  alt="Profile Preview"
                                />
                              </div>
                              <div className="upload-text">
                                <h6>{profileImageInfo.fileName}</h6>
                                <p>{profileImageInfo.fileSize}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-wrap">
                        <div className="upload-info">
                          <label>
                            Upload E-Signature{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <p>
                            <i className="feather icon-alert-circle" /> Maximum
                            File size 2MB & PNG, JPEG, SVG
                          </p>
                          <label className="choose-file">
                            <Controller
                              name="profile_signature"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="file"
                                  accept="image/jpeg, image/png, image/svg+xml, image/jpg"
                                  className="form-control"
                                  onChange={(e) => {
                                    field.onChange(e.target.files?.[0]);
                                    handleFileChange(e, "signature");
                                  }}
                                />
                              )}
                            />
                            <span>
                              <i className="feather icon-upload" /> Choose to
                              File Upload
                            </span>
                          </label>
                          {errors.profile_signature && (
                            <small className="text-danger">
                              {errors.profile_signature.message}
                            </small>
                          )}
                          {signatureInfo.preview && (
                            <div className="upload-signature">
                              <img
                                src={signatureInfo.preview}
                                alt="Signature"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sidebar-switch">
                  <h6>Status</h6>
                  <div className="toggles">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={field.value === true ? true : false}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                          <div className="slider round">
                            <span className="on">
                              <i className="ti ti-check"></i>
                            </span>
                            <span className="off">
                              <i className="ti ti-x"></i>
                            </span>
                          </div>
                        </label>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer sidebar-btn">
              <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
                Cancel
              </Link>
              <button className="btn btn-add" type="submit">
                {id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEditServiceManager;
