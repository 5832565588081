import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { light } from "../../../../utils/imagepath";
import { yup } from "../../../../utils/shared.module";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import {
  DESCRIPTION_LENGTH,
  nameMaxLength,
} from "../../../../utils/patterns/regex.pattern";
import { clearEditData } from "../../../../core/redux/commonSlice";

const AddTutorial = ({ successcall = () => {} }) => {
  const { postData, putData } = useContext(ApiServiceContext);

  const data = useSelector((state: any) => state.common.editData);
  const tutorial_id = data?.tutorial_id;

  const validationSchema = Yup.object().shape({
    title: Yup.string().trim().required("Tutorial name is required"),
    description: Yup.string()
      .trim()
      .max(255, "Maximum 255 characters allowed")
      .required("Tutorial description is required"),
    tutorial_video: Yup.mixed()
      .test("fileSize", "File size should not exceed 100MB", (value:any) => {
        if (!value || !value.length || typeof value === "string") return true; 
        return value.size <= 100 * 1024 * 1024;
      })
      .test("fileType", "Unsupported file format", (value:any) => {
        if (!value || !value.length || typeof value === "string") return true; 
        return ["video/mp4", "video/avi", "video/wmv", "video/webm"].includes(
          value.type
        );
      })
      .required("Tutorial video is required"),
    status: yup.boolean().required("Status is required"),
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [videoPreview, setVideoPreview] = useState<string | null>(null);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (tutorial_id) {
      setValue("title", data.title || "");
      setValue("description", data.description || "");
      setValue("status", data.status === 1 ? true : false);
      setValue("tutorial_video", data.tutorial_video || "");

      if (data.tutorial_video) {
        setVideoPreview(data.tutorial_video);
      }
    }
  }, [data, setValue, tutorial_id]);

  const onSubmit = async (data: any) => {
    try {
      const tutorial_status: any = data.status == true ? 1 : 2;
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("tutorial_video", data.tutorial_video);
      formData.append("status", tutorial_status);
      if (tutorial_id) {
        formData.append("id", tutorial_id);
      }

      let urls = tutorial_id
        ? end_points.update_tutorial.url
        : end_points.create_tutorial.url;
      dispatch(setAddCanvasSidebar(!offcanvas));
      const response = tutorial_id
        ? await putData(urls, formData)
        : await postData(urls, formData);

      if (response.status === 200) {
       toast.success(response?.data?.response?.responseMessage);
        setVideoPreview(null);
        setSelectedFile(null);
        dispatch(clearEditData());
        successcall();
      }
    } catch (e) {
    }
  };

  const handleCustomSubmit = () => {
    setButtonDisable(true);
    handleSubmit(onSubmit)();
  };


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0] || null;

    setSelectedFile(file);
    setValue("tutorial_video", file, { shouldValidate: true });

    if (file) {
      const fileURL = URL.createObjectURL(file);
      setVideoPreview(fileURL);
    } else if (data.tutorial_video) {
      setVideoPreview(data.tutorial_video);
    }
  };

  const dispatch = useDispatch();

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
    dispatch(clearEditData());
    reset();
  };

  return (
    <>
      <div
        className={`truck-list-sidebar ${
          offcanvas ? "truck-list-sidebar-open" : ""
        }`}
      >
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {tutorial_id ? "Edit" : "Add"} Tutorial{" "}
            </h4>
            <Link onClick={handleOffcanvas} to="#" className="clear-truck-list">
              <i className="fas fa-xmark" />
            </Link>
          </div>
          <div className="card-body">
            <div className="truck-information mb-0">
              <h4 className="mb-1">Video Tutorial</h4>
              <p className="text-muted">
                Please below details to create new tutorial
              </p>
              <div className="truck-information-grid mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label>
                        Tutorial Name <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="title"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            {...field}
                            onBlur={() => trigger("title")}
                            maxLength={nameMaxLength}
                            onKeyDown={(e) => {
                              if (
                                !/[a-zA-Z\s]/.test(e.key) &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("title");
                            }}
                          />
                        )}
                      />
                      {errors.title && (
                        <small className="text-danger">
                          {errors.title.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-2">
                      <label>
                        Tutorial Description <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <textarea
                            maxLength={DESCRIPTION_LENGTH}
                            className="form-control"
                            placeholder="Enter Description"
                            {...field}
                            onBlur={() => trigger("description")}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("description");
                            }}
                          />
                        )}
                      />
                      {errors.description && (
                        <small className="text-danger">
                          {errors.description.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <p className="text-muted">
                        <i className="ti ti-info-circle me-2" />
                        Maximum 255 Characters
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-4">
                      <label className="mb-2">
                        Tutorial Video <span className="manitary">*</span>
                      </label>
                      <div className="file-upload">
                        <Controller
                          name="tutorial_video"
                          control={control}
                          render={({ field }) => (
                            <div className="file-upload">
                              <input type="file" accept="video/mp4, video/x-msvideo, video/x-ms-wmv, video/webm" onChange={handleFileChange} />
                              <span>
                                <i className="ti ti-upload me-2" />
                                Choose File to Upload
                              </span>
                            </div>
                          )}
                        />
                        {errors.tutorial_video && (
                          <small className="text-danger">
                            {errors.tutorial_video.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <p className="text-muted">
                        <i className="ti ti-info-circle me-2" />
                        Maximum File size 100MB & MP4, AVI, WMV, WebM
                      </p>
                    </div>
                  </div>
                  {videoPreview && (
                    <div className="col-md-12">
                      <div className="video-details">
                        <div className="video-details-img">
                          {videoPreview ? (
                            <video width="100%" controls>
                              <source
                                src={videoPreview}
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img src={light} alt="Profiles" />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-12 d-flex align-items-center justify-content-between mb-3">
                <h6>Status</h6>
                <div className="toggles">
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={true}
                    render={({ field }) => (
                      <label className="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                        <div className="slider round">
                          <span className="on">
                            <i className="ti ti-check"></i>
                          </span>
                          <span className="off">
                            <i className="ti ti-x"></i>
                          </span>
                        </div>
                      </label>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer canvas-btn tutorial-footer">
            <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
              Cancel
            </Link>
            <Link to="#" className="btn btn-add" onClick={handleCustomSubmit}>
              {tutorial_id ? "Update" : "Add"}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTutorial;
